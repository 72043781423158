import React from 'react'
import { Section, Container } from './layout.jsx'
import Empty from './empty'

import './__styles__/links.module.scss'

const Links = ({ data }) => (
  <Section>
    <Container>
      <h3 styleName="title">Links</h3>
    </Container>
    {data.map(link => (
      <div key={link.id} styleName="row">
        <Container>
          <div styleName="container">
            <a href={link.url} styleName="link">
              {link.name}
            </a>
          </div>
        </Container>
      </div>
    ))}
    {!data.length && <Empty type="Links" />}
  </Section>
)

export default Links
